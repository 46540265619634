body {
    --orange-color: #ffa701;
    --orange-lighter-color: #eeb274;
    --orange-darker-color: #d56217;
    --danger-color: #B00020;
    --success-color: #96b616;
    --success-color-dark: #718910;
    --success-color-light: #b0d51a;
    --gray-background: #f7f7f7;
    --gray: #979797;
    --blue-chat: #0A80FE;
    --gray-chat: #EBEBEB;

    --dark-theme-accent-color: var(--primary-color);
    --dark-theme-text-color: #ffffff;
    --dark-theme-background-color: #333333;
    --dark-theme-active-background-color: var(--dark-theme-accent-color);
    --dark-theme-active-text-color: #ffffff;
    --dark-theme-active-nobg-text-color: #ffffff;
    --dark-theme-border-color: #fafafa6e;
    --dark-theme-active-item-background-color: #a0a0a0;

    --light-theme-accent-color: var(--primary-color);
    --light-theme-text-color: #000000;
    --light-theme-background-color: #ffffff;
    --light-theme-active-background-color: var(--light-theme-accent-color);
    --light-theme-active-text-color: #ffffff;
    --light-theme-active-nobg-text-color: #000000;
    --light-theme-border-color: #ccccccab;
    --light-theme-active-item-background-color: #e9e8e8;

    --alt-theme-accent-color: var(--success-color);
    --alt-theme-text-color: #ffffff;
    --alt-theme-background-color: #686868;
    --alt-theme-active-background-color: var(--alt-theme-accent-color);
    --alt-theme-active-text-color: #ffffff;
    --alt-theme-active-nobg-text-color: #ffffff;
    --alt-theme-border-color: #fafafa6e;
    --alt-theme-active-item-background-color: #a0a0a0;

    --application-header-background-color: #686868;
    --application-header-color: var(--text-primary-color);

    --toolbar-height: 60px;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    color: var(--text-primary-color) !important;
}

.mat-primary {
    &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: var(--primary-color);
    }
}

.mat-warn {
    &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: var(--warn-color);
    }
}

.mat-accent {
    &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: var(--accent-color);
    }
}

.mat-orange {
    &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: var(--orange-color);
    }
    &.mdc-button {
        color: var(--orange-color) !important;
    }
}

.mat-danger {
    &.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: var(--danger-color);
    }

    &.mdc-button {
        color: var(--danger-color) !important;
    }
}

.mat-flat-button.mat-orange,
.mat-raised-button.mat-orange,
.mat-fab.mat-orange,
.mat-mini-fab.mat-orange {
    background-color: var(--orange-color);
    color: #ffffff;
}
