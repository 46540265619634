.text-center {
    text-align: center !important;
    align-items: center;
    align-self: center;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-primary {
    color: var(--primary-color);
}

.text-accent {
    color: var(--accent-color);
}

.text-orange {
    color: var(--orange-color);
}

.text-warn {
    color: var(--warn-color);
}

mat-form-field {
    width: 100%;
}

.pointer {
    color: var(--primary-color);
    cursor: pointer !important;
}

.no-color {
    color: black !important;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.row + .row {
    padding-top: 10px;
}

.column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.align {
    align-items: baseline;
}

.inline {
    display: inline-block;
}

.inline-flex {
    display: inline-flex;
}

.sup-height {
    align-items: flex-end;
}

.nowrap {
    white-space: nowrap;

    .mat-button {
        padding: 0 5px;
    }
}

.col {
    flex: 1;
    margin-right: 20px;
}

.col-no-mr {
    flex: 0.1;
    margin-right: 10px;
}

.col.super-extra-small {
    flex: 0.01;
}

.col.extra-extra-small {
    flex: 0.05;
}

.col.extra-small {
    flex: 0.1;
}

.col.quarter {
    flex: 0.25;
}

.col.small {
    flex: 0.5;
}

.col.medium {
    flex: 0.75;
}

.col .center-content {
    place-content: center;
}

.col:last-child {
    margin-right: 0;
}

.highlight {
    font-weight: 500;
}

.small-icon {
    font-size: 18px;
    height: 18px;
    width: 18px;
    position: relative;
    top: 3px;
}

.placeholder {
    visibility: hidden;
}

.align-bot {
    vertical-align: sub !important;
}

.semi-bold {
    font-weight: 500;
}

.margin-card {
    margin: 5px 0;
}

.p-1 {
    padding: 10px !important;
}

.m-1 {
    margin: 10px !important;
}

.expansion-padding {
    padding: 0 20px !important;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.pt-1 {
    padding-top: 10px !important;
}

.pl-2 {
    padding-left: 20px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.pdf-view {
    margin: auto;
    height: 90vh;
    border: 1px solid #dadada;
}

.auto-height {
    height: 100%;
}

.margin-auto {
    margin: auto;
}

.no-margin {
    margin: 0 !important;
}

.no-shadow {
    box-shadow: none !important;
}

.no-margin-p {
    p {
        margin: 0 !important;
    }
}

.overflow-none {
    overflow: hidden !important;
}

.mat-badge-no-content {
    .mat-badge-content {
        background: var(--orange-color) !important;
        color: white;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
}

.p-0 {
    padding: 0 !important;
}
.m-0 {
    margin: 0 !important;
}
.s-0 {
    box-shadow: none !important;
}
