.steps-section {
    margin: 0 10px;

    .scenario-step {
        padding: 2px 8px;
        min-height: 48px;
        position: relative;

        &:has(.from-step) {
            margin-top: 15px;
        }

        .from-step {
            display: flex;
            flex-direction: column;

            .from-scenario {
                display: block;
                margin-bottom: 5px;
                color: var(--primary-color);
                // position: absolute;
                // top: -15px;
            }
        }
    }

    .scenario-step:nth-child(2n) {
        background-color: rgba(186, 229, 255, 0.18);
    }
}

.scenario-name {
    .mat-mdc-form-field-infix {
        padding: 0.5em 0;
        border-top: 0;

        .mat-mdc-input-element {
            font-size: 24px;
        }
        .nominal {
            color: var(--primary-color) !important;
        }
        .exception {
            color: var(--warn-color) !important;
        }
        .extension {
            color: var(--orange-color) !important;
        }
        .alternative {
            color: var(--accent-color) !important;
        }
    }
    .mdc-line-ripple {
        display: none;
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }
    .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
    }
    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
        text-decoration: none;
        text-transform: none;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
}

use-case-scenario {
    display: grid;
    gap: 15px;
}

.use-case-scenario {

    --mat-badge-legacy-container-size: 19px;

    @for $i from 0 through 99 {
        .depth-#{$i} {
            margin-left: $i * 25px;
        }
    }

    .depth-0 {
        padding: 0 !important;
        border: none !important;
    }

    .scenario-card {
        margin: 0;
    }
}

.use-case-action {
    .action-button + .action-button {
        margin-left: 8px;
    }
    .action-button.align-bot {
        mat-icon {
            margin-top: -5px;
        }
    }
}
