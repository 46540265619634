.super-sidebar {
    width: 330px;
    height: calc(100% - 16px);
    flex-shrink: 0;
    padding: 8px;
    overflow: auto;

    &--dark {
        --theme-text-color: var(--dark-theme-text-color);
        --theme-background-color: var(--dark-theme-background-color);
        --theme-active-text-color: var(--dark-theme-active-text-color);
        --theme-active-nobg-text-color: var(--dark-theme-active-nobg-text-color);
        --theme-active-background-color: var(--dark-theme-active-background-color);
        --theme-border-color: var(--dark-theme-border-color);
        --theme-active-item-background-color: var(--dark-theme-active-item-background-color);
    }
    &--light {
        --theme-text-color: var(--light-theme-text-color);
        --theme-background-color: var(--light-theme-background-color);
        --theme-active-text-color: var(--light-theme-active-text-color);
        --theme-active-nobg-text-color: var(--light-theme-active-nobg-text-color);
        --theme-active-background-color: var(--light-theme-active-background-color);
        --theme-border-color: var(--light-theme-border-color);
        --theme-active-item-background-color: var(--light-theme-active-item-background-color);
    }
    &--alt {
        --theme-text-color: var(--alt-theme-text-color);
        --theme-background-color: var(--alt-theme-background-color);
        --theme-active-text-color: var(--alt-theme-active-text-color);
        --theme-active-nobg-text-color: var(--alt-theme-active-nobg-text-color);
        --theme-active-background-color: var(--alt-theme-active-background-color);
        --theme-border-color: var(--alt-theme-border-color);
        --theme-active-item-background-color: var(--alt-theme-active-item-background-color);
    }

    .top-nav {
        margin-bottom: 24px;
        border: solid 1px var(--theme-border-color);
        border-radius: 8px;
        padding: 16px 8px 8px;
    }

    .application-requirement-list {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        background-color: transparent;
        width: 100%;
        gap: 4px;

        .mdc-list {
            padding: 0;
        }
    }

    versionable-menu-item {
        border-radius: 4px;
        &.active,
        &:hover {
            background-color: var(--theme-active-item-background-color);
            color: var(--theme-active-nobg-text-color);
        }
    }

    .si-version {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 4px;
        cursor: pointer;
        --mat-form-field-container-height: 24px;

        .top-nav-wrapper {
            display: grid;
            grid-template-columns: 1fr 48px;
            width: 100%;
            align-items: center;
        }

        // &:hover, &.active {
        //     color: var(--theme-active-text-color);
        //     background-color: var(--theme-active-background-color);
        // }

        .si,
        .version,
        .switcher-select {
            width: 100%;
        }

        .si,
        .version,
        .application-selection {
            padding-top: 8px;
            padding-bottom: 8px;
            border-radius: 4px;
            &:hover,
            &.active {
                background-color: var(--theme-active-background-color);
                color: var(--theme-active-text-color);
                .mat-mdc-select-min-line,
                mat-label,
                .mat-mdc-select-arrow {
                    color: var(--theme-active-text-color);
                }
            }
        }

        .mdc-floating-label {
            background-color: transparent;
            color: var(--theme-text-color);
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled)
            .mdc-floating-label {
            color: var(--theme-text-color);
        }

        .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(
                .mdc-text-field--focused
            ):hover
            .mdc-floating-label {
            color: var(--theme-text-color);
        }

        .mat-mdc-select {
            color: var(--theme-text-color);
        }

        .mat-mdc-select-arrow {
            color: var(--theme-text-color);
        }

        .mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
            color: var(--theme-text-color);
        }

        &--lean {
            .switcher-select.ng-star-inserted * {
                border: none;
            }
            .mdc-text-field--outlined .mat-mdc-form-field-infix,
            .mdc-text-field--no-label .mat-mdc-form-field-infix {
                padding-top: 4px;
                padding-bottom: 4px;
            }
            .mat-mdc-text-field-wrapper
                .mat-mdc-form-field-flex
                .mat-mdc-floating-label {
                &[ng-reflect-floating='false'] {
                    top: 15px;
                }
                &[ng-reflect-floating='true'] {
                    padding-left: 3px;
                }
            }
        }
    }

    .application {
        margin-top: 4px;
        display: grid;
        padding: 8px 16px;
        grid-template-columns: 24px 1fr 24px;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;
        border-radius: 4px;
        &:hover,
        &.active {
            color: var(--theme-active-text-color);
            background-color: var(--theme-active-background-color);
        }
    }

    .sidenav-navigation {
        --mdc-list-list-item-one-line-container-height: auto;

        @for $i from 0 through 99 {
            .depth-#{$i} {
                margin-left: $i * 20px;
                width: calc(100% - ($i * 20px)) !important;
            }
        }

        .nav-plan {
            font-size: 14px;
            font-weight: 400;
            .active {
                color: var(--theme-text-color);
            }
        }

        .mat-mdc-list-item {
            color: var(--theme-text-color);
            --mdc-list-list-item-label-text-color: var(--theme-text-color);
            display: flex;
            width: 100%;
            padding: 8px 16px;
            border-radius: 4px;
            margin-top: 4px;
            .mat-mdc-list-item-unscoped-content {
                display: grid;
                grid-template-columns: 24px 1fr;
                column-gap: 8px;
                align-items: center;
            }
            &.active,
            &:hover {
                --mdc-list-list-item-label-text-color: var(--theme-active-nobg-text-color);
                color: var(--theme-active-nobg-text-color);
                .mat-mdc-list-item-unscoped-content {
                    color: var(--theme-active-nobg-text-color);
                }
            }
            &.first-element {
                font-weight: bold;
                font-size: 120%;
                .mat-mdc-list-item-unscoped-content {
                    grid-template-columns: 1fr;
                }
                .material-symbols-outlined {
                    display: none;
                }
            }

            .mdc-list-item__content {
                list-style: none;
            }
        }

        .mdc-list-item__primary-text {
            white-space: normal;
        }
    }

    .breadcrumb {
        color: var(--theme-text-color);
        background-color: var(--theme-background-color);
        cursor: pointer;
    }
}

.main-logo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
