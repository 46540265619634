.mat-mdc-dialog-container {
    min-width: 80vw;
    h2.mdc-dialog__title {
        font-family: 'Noto Sans', 'Helvetica Neue', sans-serif;
        font-size: 20px;
        margin-bottom: 15px;
        letter-spacing: normal;
    }
    @media screen and (max-width: 800px) {
        width: 80vw !important;
    }
    @media screen and (min-width: 1300px) {
        min-width: 1000px;
        width: 1000px !important;
    }

    &.mdc-dialog__content {
        padding-top: 10px !important;
        color: black !important;
        font-family: var(--mdc-dialog-supporting-text-font, "Noto Sans") !important;
        line-height: var(--mdc-dialog-supporting-text-line-height, 1.2) !important;
        font-size: var(--mdc-dialog-supporting-text-size, 15px) !important;
        font-weight: var(--mdc-dialog-supporting-text-weight, normal) !important;
        letter-spacing: var(--mdc-dialog-supporting-text-tracking, normal) !important;
    }
}

.mat-mdc-dialog-actions {
    .saving-state {
        display: flex;
        color: var(--primary-color);
        padding: 20px;
        span {
            padding: 0 20px;
        }
    }

    .split-button-left {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-left: 5px;
    }

    .split-button-right {
        min-width: unset !important;
        padding: 0;
        margin-left: 0 !important;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px solid #fafafa;
        .mat-icon {
            margin-right: 0px !important;
        }
    }
}

.mention {
    background: rgba(176, 193, 239, 0.25) !important;
    color: var(--primary-color) !important;
}

.mat-mdc-dialog-actions .split-button-right .mat-icon {
    margin-left: 0;
}

.mdc-dialog__actions {
    padding: 8px 24px!important;
}

.mdc-dialog__title+.mdc-dialog__content, .mdc-dialog__header+.mdc-dialog__content {
    padding-top: 5px !important;
}
