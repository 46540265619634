kbd {
    margin-right: 6px;
    background-color: #fafbfc;
    border: 1px solid #d1d5da;
    border-bottom-color: #c6cbd1;
    border-radius: 3px;
    box-shadow: inset 0 -1px 0 #c6cbd1;
    color: #444d56;
    font-size: 12px;
    padding: 3px 5px;
}
.hotkeys-table {
    margin: 20px 0;
    h3 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
    }
    .hotkeys-line:nth-child(2n + 1) {
        background-color: rgba(115, 150, 173, 0.18);
    }
}

.hotkeys-about {
    font-style: italic;
    font-size: 12px;
}

.dialog-inline-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        margin: 0 15px 20px 0;
    }
}
