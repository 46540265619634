@use '@angular/material' as mat;

.error-page {
    margin: 40px 0;

    .content {
        padding: 60px 40px 30px;
        margin: 8px auto;
        width: 30%;
        max-width: 560px;
        min-width: 350px;
        @include mat.elevation(8, #000000, 0.07);

        .mat-card-header-text {
            padding: 30px 0 0;
            width: 100%;
        }

        .error {
            font-size: 96px;
            font-weight: 500;
            text-align: center;
            background: linear-gradient(
                -45deg,
                #ee7752,
                #e73c7e,
                #23a6d5,
                #23d5ab
            );
            background-size: 200% auto;
            color: #000;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            animation: gradient 15s ease infinite;
            @keyframes gradient {
                0% {
                    background-position: 0 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0 50%;
                }
            }
        }

        .home-button {
            margin: 20px 0;
        }

        .image-container {
            margin: 50px 0 0;
            .image-logo {
                width: 50%;
                align-items: center;
                text-align: center;
            }
        }
    }
}
