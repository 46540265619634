///remove this file if the dashboard is reworked
.c-charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .chart {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: flex-end;

        .title {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
            width: 100%;
            margin-bottom: 15px;
        }

        .mat-button {
            margin-top: 8px;
        }

        .donut-cell {
            position: relative;

            .donut-div {
                width: 200px;
                height: 200px;
                position: relative;
            }

            .centerLabel {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 100%;
                height: 100%;

                .label {
                    font-weight: 550;
                    text-transform: uppercase;
                    margin: 0;
                    padding: 0;
                    font-size: 14px;

                    &:first-child {
                        margin-top: 5px;
                    }
                }

                .requirement-charts {
                    margin: 15px 0 10px;

                    .value {
                        font-size: 36px;
                        color: var(--primary-color);
                    }
                }

                .primary {
                    padding: 10px 0;
                    color: var(--primary-color);
                    font-size: 26px;
                }
            }
        }

        &.component {
        }
    }
}
path[stroke-width='1'] {
    stroke: transparent;
}
