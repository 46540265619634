.mat-optgroup.select-all {
    .mat-optgroup-label {
        cursor: pointer;

        &:hover {
            background-color: rgba(44, 44, 44, 0.04);
        }
    }
}

.drag-card-use-case {
    .ck-editor__editable_inline {
        min-height: 0;
    }
}

.constraint-item {
    margin-block-end: 0.5em;
    margin-block-start: 0.5em;
    padding-inline-start: 20px;

    &--placeholder {
        list-style: none;
    }
}

.relation-form {
    flex-wrap: nowrap;
    flex-grow: 1;
    margin-bottom: 5px;

    .rel-row {
        display: flex;
        gap: 10px;

        @media (min-width: 1240px) {
            flex-wrap: nowrap;
        }
    }

    &.disable {
        background-color: #f4f4f4;
        border-radius: 6px;

        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
            padding-bottom: 1em;
        }

        .row + .row {
            padding-top: 0;
        }
    }
}

.relation-form-name {
    padding: 0.3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.relation-form-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mat-mdc-form-field-error {
    font-size: 12px !important;
}

.mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
}

.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    max-width: unset !important;
    background-color: #ffffff;
    padding-right: 5px;
}

.relation-form.disable {
    .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        background-color: #f4f4f4;
    }
}

.mat-select-search-inside-mat-option {
    .mat-select-search-input {
        border: none !important;
        &:focus {
            outline: none !important;
        }
    }
}

.mat-select-search-inner {
    .mat-select-search-inner-row {
        .mat-select-search-input {
            background-color: #ffffff;
        }
    }
}

.ckeditor-label {
    display: block;
    margin-bottom: 5px;
}

.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    mat-error {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
