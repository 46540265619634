.drag-and-drop-list {
    max-width: 100%;
    min-height: 30px;
    display: block;
    border-radius: 4px;
    overflow: hidden;
}

.handle-drag {
    .mat-icon {
        cursor: grab;
    }
}

.data-box {
    margin: 5px 10px;
    padding: 10px 10px;
    border-radius: 4px;
    color: var(--primary-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: default;
    background: #ffffff;
    font-size: 16px;
    flex-grow: 1;
    flex-basis: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;

    &.clickable {
        padding: 20px 10px;
        cursor: pointer;
    }

    .content {
        width: 100%;
        display: flex;
        align-items: center;

        a {
            text-decoration: none;
            color: var(--primary-color);
        }

        a:hover {
            transition: 0.3s;
            color: var(--warn-color);
        }

        .mat-icon {
            align-items: baseline;
        }

        .mat-icon:hover {
            cursor: grab;
        }

        .cdk-icon {
            .mat-icon {
                display: block;
            }
        }
    }

    .mat-icon-button {
        transform: scale(1.2);

        .mat-icon:hover {
            cursor: pointer !important;
        }
    }
}

.cdk-drag-disabled {
    cursor: default;

    .content {
        margin-left: 20px;
        justify-content: space-between;

        a {
            text-decoration: none;
            color: var(--primary-color);
        }

        a:hover {
            transition: 0.3s;
            color: var(--warn-color);
        }

        .mat-icon {
            display: none;
        }
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-and-drop-list
    .cdk-drop-list-dragging
    .data-box
    :not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.custom-placeholder {
    margin: 30px 10px;
    border-radius: 4px;
    background: #ececec;
    border: dotted 3px var(--primary-color);
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.suppliers-list {
    margin: 0 10px;
    max-width: 100%;
    min-height: 30px;
    display: block;
    border-radius: 4px;

    a {
        text-decoration: none;
        color: var(--primary-color);
    }

    a:hover {
        transition: 0.3s;
        color: var(--warn-color);
    }

    .mat-list-item {
        color: var(--primary-color);
        margin: 20px 0;
        padding: 10px 10px;
        background: #eeeeee;
        font-size: 16px;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }
}

.dependencies-header {
    width: 100%;

    .dependencies-title {
        margin: 10px 20px 0 10px;

        .mat-card-title {
            margin-top: 10px;
        }
    }

    .dependencies-action {
        margin: 20px 20px 0 10px;
    }

    .dependencies-button {
        display: flex;
        flex-flow: row;
        flex-direction: row-reverse;

        .button-cancel {
            margin-left: 20px;
        }

        .button-save {
            margin: 0;
        }
    }
}

.cdk-drag-placeholder {
    opacity: 0;
}

.drag-drop-container {
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;
}

.cdk-drop-list {
    .mat-expansion-panel-header-title {
        font-size: 14px;
        font-weight: 400;
    }
}
