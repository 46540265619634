@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,300,0,0');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500&display=swap');

mat.$theme-ignore-duplication-warnings: true;

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
);

body {
    --primary-color: #0079bf;
    --primary-color-alt: #5199c4;
    --primary-lighter-color: #e0eff7;
    --primary-darker-color: #005ca9;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
    50: #e0eff7,
    100: #b3d7ec,
    200: #80bcdf,
    300: #4da1d2,
    400: #268dc9,
    500: #0079bf,
    600: #0071b9,
    700: #0066b1,
    800: #005ca9,
    900: #00499b,
    A100: #c6ddff,
    A200: #93beff,
    A400: #609fff,
    A700: #4790ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);
$my-primary: mat.m2-define-palette($mat-primary, 500);

$mat-primary-alt: (
    50: #e0eff7,
    100: #b3d7ec,
    200: #80bcdf,
    300: #4da1d2,
    400: #268dc9,
    500: #0079bf,
    600: #0071b9,
    700: #0066b1,
    800: #005ca9,
    900: #00499b,
    A100: #c6ddff,
    A200: #93beff,
    A400: #609fff,
    A700: #4790ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);
$my-primary-alt: mat.m2-define-palette($mat-primary-alt, 500, A100, 500);

body {
    --accent-color: #66a500;
    --accent-lighter-color: #d1e4b3;
    --accent-darker-color: #498a00;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
    50: #edf4e0,
    100: #d1e4b3,
    200: #b3d280,
    300: #94c04d,
    400: #7db326,
    500: #66a500,
    600: #5e9d00,
    700: #539300,
    800: #498a00,
    900: #387900,
    A100: #c8ffa7,
    A200: #a8ff74,
    A400: #88ff41,
    A700: #79ff27,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);
$my-accent: mat.m2-define-palette($mat-accent, 500);

$mat-accent-alt: (
    50: #edf4e0,
    100: #d1e4b3,
    200: #b3d280,
    300: #94c04d,
    400: #7db326,
    500: #66a500,
    600: #5e9d00,
    700: #539300,
    800: #498a00,
    900: #387900,
    A100: #c8ffa7,
    A200: #a8ff74,
    A400: #88ff41,
    A700: #79ff27,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);
$my-accent-alt: mat.m2-define-palette($mat-accent-alt, 500, A100, 500);

body {
    --warn-color: #b00020;
    --warn-color-alt: #c4685c;
    --warn-lighter-color: #dfbbb6;
    --warn-lighter-color-alt: #faaea4;
    --warn-darker-color: #941c0c;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
    50: #f2e4e2,
    100: #dfbbb6,
    200: #ca8e86,
    300: #b46055,
    400: #a43e30,
    500: #941c0c,
    600: #8c190a,
    700: #811408,
    800: #771106,
    900: #650903,
    A100: #ff9895,
    A200: #ff6762,
    A400: #ff362f,
    A700: #ff1d15,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);
$my-warn: mat.m2-define-palette($mat-warn, 500);

$mat-warn-alt: (
    50: #f2e4e2,
    100: #dfbbb6,
    200: #ca8e86,
    300: #b46055,
    400: #a43e30,
    500: #941c0c,
    600: #8c190a,
    700: #811408,
    800: #771106,
    900: #650903,
    A100: #ff9895,
    A200: #ff6762,
    A400: #ff362f,
    A700: #ff1d15,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);
$my-warn-alt: mat.m2-define-palette($mat-warn-alt, 500, A100, 500);

$theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
            warn: $my-warn,
            is-dark: false,
            foreground: $mat-light-theme-foreground,
            background: $mat-light-theme-background,
        ),
        density: -2,
    )
);

$dark-theme: mat.m2-define-dark-theme(
    (
        color: (
            primary: $my-primary-alt,
            accent: $my-accent-alt,
            warn: $my-warn-alt,
            is-dark: true,
            foreground: $mat-dark-theme-foreground,
            background: $mat-dark-theme-background,
        ),
        density: -2,
    )
);

@include mat.all-component-themes($theme);

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
}

.mat-icon, .material-icons {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

.mat-badge-content, .mat-badge-small .mat-badge-content {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Noto Sans', sans-serif !important;
    font-size: 12px!important;
}
