.mat-mdc-snack-bar-container {
    color: #ffffff !important;

    &.success {
        .mdc-snackbar__surface {
            color: #ffffff !important;
            background: var(--accent-color) !important;
        }
    }

    &.warning {
        .mdc-snackbar__surface {
            color: #ffffff !important;
            background: var(--orange-color) !important;
        }
    }

    &.error {
        .mdc-snackbar__surface {
            color: #ffffff !important;
            background: var(--warn-color) !important;
        }
    }

    .mat-mdc-snack-bar-action {
        color: #ffffff !important;
    }
}
