.discussion-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - var(--toolbar-height) - 40px - 32px - 68px);
    gap: 8px;

    > h2 {
        border-bottom: solid 1px var(--light-theme-border-color);
        padding-bottom: 15px;
    }

    .discussion-item {
        width: 100%;
        // box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;
        border-radius: var(--mdc-elevated-card-container-shape);
        cursor: pointer;

        &.close {
            background: #eaeaea;
        }
    }

    .discussion-item-container {
        border-radius: 5px;
        padding: 10px;

        h2 {
            margin: 0 !important;
        }
    }
}

.discussion-block + .discussion-block:has(.comment-and-reply) {
    border-top: solid 1px #dadada;
}

.comment-container {
    .mat-mdc-button-base {
        display: flex;
    }

    .mat-mdc-card {
        box-shadow: none;
    }

    .comment-and-reply {
        display: flex;
        flex-direction: column;

        &.detail {
            .comments-section {
                height: auto;
            }
        }
    }

    .reply-comment {
        margin-top: 30px;

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }

    .menu-button {
        display: flex;
        flex-direction: column;
    }

    .user,
    .comment-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: var(--mat-app-text-color);

        .comment-content {
            border-radius: 5px;
            padding: 10px;
            min-width: 150px;
        }
    }

    .user {
        padding-left: 60px;

        .comment-content {
            background-color: var(--blue-chat);
            color: white;
        }
    }

    .comment-info {
        padding-right: 60px;

        .comment-content {
            background-color: var(--gray-chat);
            color: var(--mat-app-text-color);
        }
    }

    .comments-action {
        display: flex;
        justify-content: flex-end;

        .mdc-button {
            margin-bottom: 10px;
        }
    }

    .comment-header {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;

        h2 {
            margin: 0 !important;
        }
    }

    .comment-author {
        color: var(--primary-color);
    }

    .comment-date-action {
        display: flex;
        justify-content: space-between;
        color: var(--gray);
    }

    .comments-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow-y: auto;
    }

    .comment-first {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        width: 100%;
    }

    .comment-action-layer {
        display: flex;
        flex-direction: column;
    }

    &.inactive {
        background-color: #eaeaea;

        p,
        .comment-content {
            color: grey;
        }

        mat-icon {
            visibility: hidden;
        }
    }
}

.comment-send {
    display: flex;
    justify-content: flex-end;

    .mat-raised-button {
        margin: 0 10px;
    }

    button + button {
        margin-left: 10px;
    }
}

@media screen and (max-width: 1100px) {
    .all-discussion-container {
        flex-direction: column !important;

        .discussion-section {
            margin-bottom: 20px;
        }
    }
}

.discussion-item:hover {
    background-color: var(--gray-chat);
}

.padding-comment-title {
    padding: 15px;
}

.discussion-header {
    display: flex;
    justify-content: space-between;
}

.all-discussion-container {
    display: flex;
    flex-direction: row;
}

.selected {
    background-color: var(--gray-chat);
}
