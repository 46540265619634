/* You can add global styles to this file, and also import other style files */
@import 'scss/custom-theme';
@import 'scss/custom-colors';
@import 'scss/helpers';
@import 'scss/main-layout';
@import 'scss/login';
@import 'scss/cards';
@import 'scss/dialogs';
@import 'scss/chips';
@import 'scss/application';
@import 'scss/company';
@import 'scss/tables';
@import 'scss/charts';
@import 'scss/error-page';
@import 'scss/forms';
@import 'scss/snack-bars';
@import 'scss/sidenav';
@import 'scss/dragdrop';
@import 'scss/ckeditor';
@import 'scss/uml';
@import 'scss/use-cases';
@import 'scss/hotkeys';
@import 'scss/discussions';
@import 'scss/drawer';
@import 'scss/versionable';
@import 'scss/typography';
@import 'scss/buttons';
@import 'scss/super-sidebar';
