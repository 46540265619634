.versionable-content {
    display: flex;
    justify-content: space-between;
    height: 100%;

    .versionable-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - 80px);
    }

    .group-cards {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .mat-mdc-card:not(.sticky-card--bottom) {
        margin: 0;
    }

    .form-field-inactive + .form-field-inactive {
        margin-top: 30px;
    }

    .mat-mdc-button > .mat-icon {
        margin-right: 0;
    }

    .toggle-button {
        position: fixed;
        z-index: 1000;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        min-width: 36px;
        padding: 0;
        border-style: solid;
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        &.-left {
            left: 8px;
        }
        &.-right {
            right: 0;
            .mat-icon {
                margin-right: 0px !important;
            }
            &.-second {
                margin-top: 40px;
                right: 0;
            }
        }
    }

    .mat-expansion-panel-header {
        height: auto;
    }

    data-model-class-diagram {
        display: block;
    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        padding: 10px;
    }
}
