.drawer-preview,
.disable-drawer-preview {
    z-index: 999;
    position: fixed;
    top: var(--toolbar-height);
    right: 0;
    height: calc(100vh - var(--toolbar-height));
    background-color: $light-background;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.07), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.07);
    transition: width ease 0.2s;
    overflow: auto;

    .drawer-header {
        z-index: 100;
        position: sticky;
        top: 0;
        padding: 15px 30px;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        align-items: center;
        background-color: white;
        border-bottom: solid 1px #c4c4c4;

        &:has(.drawer-details) {
            column-gap: 15px;
            grid-template-columns: auto max-content;
        }

        .actions {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        a, button {
            color: black;
            display: inline-flex;
        }
    }

    @media (max-width: 767px) {
        width: calc(100vw - 47px);
    }
}

.drawer-preview {
    width: 80vw;
    max-width: calc(100vw - 400px);

    .mat-drawer-container {
        overflow: unset;
        padding: 15px 30px;
    }

    @media (max-width: 1499px) {
        width: 100vw;
    }
}

.mat-drawer {
    .mat-drawer-inner-container {
        overflow-x: hidden;
    }
}

.disable-drawer-preview {
    width: 0;
}

.mat-drawer[position="start"] {
    left: 0;
}

.mat-drawer[position="end"] {
    right: 0;
}

.mat-drawer-container {
    padding: 0;
}

:not(.drawer-preview) {
    .mat-drawer-content {
        overflow: unset;
    }

    .mat-drawer-container {
        overflow: visible!important;
    }

    .mat-drawer.mat-drawer-end {
        top: var(--toolbar-height);
        right: 0;
        height: calc(100vh - var(--toolbar-height)) !important;
        position: fixed;
        overflow: auto;
        .mat-drawer-backdrop {
            display: none;
        }
    }
}

.mat-drawer-side.mat-drawer.mat-drawer-end {
    top: var(--toolbar-height) !important;
    right: 0 !important;

    .comment-container {
        max-width: 500px;
    }
}

.mat-drawer-transition .mat-drawer-backdrop {
    position: fixed;
}


.mat-drawer-container.mat-sidenav-container.drawer-main-container {
    .mat-drawer-backdrop {
        display: none;
    }
}

.mat-drawer.mat-sidenav.mat-drawer-end.mat-drawer-side.mat-drawer-opened {
    background-color: #ffffff;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.07), 0px 8px 10px 1px rgba(0, 0, 0, 0.07), 0px 3px 14px 2px rgba(0, 0, 0, 0.07);
}

.mat-drawer.sidenav-dependencies {
    .data-box .mat-mdc-button {
        --mdc-text-button-container-height: auto;
        --mdc-text-button-label-text-size: 14.5px;
    }
}
