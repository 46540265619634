body {
    --mdc-chip-container-height: 24px;
}

mat-mdc-chip, mat-chip {
    &.mat-mdc-chip {
        font-weight: 400;
        letter-spacing: normal;
        min-height: 24px;
        zoom: 80%;
    }
}
