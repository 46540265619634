.mat-mdc-card {
    padding: 16px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.07),
        0px 8px 10px 1px rgba(0, 0, 0, 0.07),
        0px 3px 14px 2px rgba(0, 0, 0, 0.07);

    .mat-mdc-card-title {
        padding-top: 0;
        padding-bottom: 15px;
    }

    .mat-mdc-card-content {
        padding: 0;
    }

    .mat-card-title--flex {
        display: grid;
        grid-template-columns: auto max-content minmax(0, max-content);
        column-gap: 10px;
        width: 100%;

        h1 {
            font-size: 23px;
            font-weight: 400;
        }

        .card-subtitle {
            font-size: 16px;
            font-weight: 400;
        }

        &.shadow {
            min-height: 48px;
            box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
            align-items: center;
        }

        &.pointer-click {
            cursor: pointer;
        }

        &.sticky {
            z-index: 99;
            position: sticky;
            top: 5px;
            background-color: white;
        }

        .usage-title {
            font-size: 12px;
        }

        .usage-badge {
            font-size: 16px;
        }

        .button-row {
            .title-button + .title-button {
                margin-left: 8px;
            }
        }
        .mat-expansion-panel {
            box-shadow: none;
            background: transparent;
            &:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
                background: transparent;
            }
        }
    }

    .mat-mdc-card, .mat-card-title--flex.shadow {
        box-shadow: none;
        background-color: var(--gray-background);
    }

    .title-card {
        h1 {
            display: flex;
            margin-top: 0;
            margin-bottom: 10px;
            max-width: 700px;

            .mat-chip {
                margin-left: 10px;
                align-self: center;
            }

            app-version-chip {
                display: contents;
            }
        }

        h3 {
            font-weight: 400;
            margin-bottom: 5px;
            margin-top: 0;
        }

        .title {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--primary-color);

            .mat-icon {
                font-size: 18px;
            }

            h1 {
                text-transform: initial;
            }

            &.nominal {
                color: var(--primary-color);

                .mat-icon {
                    font-size: 16px;
                    height: 16px !important;
                    width: 16px !important;
                }
            }

            &.exception {
                color: var(--warn-color);

                .mat-icon {
                    font-size: 16px;
                    height: 16px !important;
                    width: 16px !important;
                }
            }

            &.alternative {
                color: var(--accent-color);

                .mat-icon {
                    font-size: 16px;
                    height: 16px !important;
                    width: 16px !important;
                }
            }

            &.extension {
                color: var(--orange-color);

                .mat-icon {
                    font-size: 16px;
                    height: 16px !important;
                    width: 16px !important;
                }
            }
        }
    }

    &.element-card {
        background-color: var(--gray-background);
    }

    .button-scenario {
        button {
            margin: 0 10px;
        }
    }

    a {
        text-decoration: none;

        .feature-linked {
            background-color: #f3f3f3;
            color: var(--text-primary-lighter-color);
            align-items: baseline;
            margin: 10px 0;
            border-radius: 5px;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            cursor: pointer;

            .link-title {
                margin-left: 10px;
                font-weight: 500;
                font-size: 18px;
                display: flex;
                align-self: center;
                justify-content: space-between;

                .mat-chip {
                    margin-left: 10px;
                }
            }
        }
    }

    &.version-information {
        padding: 8px 0 0 8px;
        background-color: var(--gray-background);
        border-radius: 4px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #9d9fa1;
        }
    }
}

.inner-card {
    margin: 20px 0;
    border-radius: 6px;
    padding: 0 10px;
}

.relationship-card {
    margin: 10px 0;
    border-radius: 6px;
    padding: 10px;
    flex-grow: 1;
}

.drag-cards-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 20px;
}

.drag-card {
    margin: 10px 0;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid var(--primary-color);
    &.page {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    }
}

.drag-card-use-case {
    border-radius: 4px;
    background: #fff;
    margin: 10px 0;
    border: 1px solid lightgray;
    padding: 5px 30px;

    .step-user {
        font-size: 16px;
        font-weight: 500;
    }

    .mat-form-field-wrapper {
        padding-bottom: 1em;
    }

    .mat-button-disabled {
        .material-icons {
            .mat-badge-content {
                background: var(--primary-lighter-color);
            }
        }
    }

    .error-message {
        display: none;
    }

    &.error {
        border-left: 4px solid var(--warn-color);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .error-message {
            margin: 10px 70px;
            padding: 0 20px;
            display: block;
            font-weight: 500;
            color: var(--warn-color);
        }

        .row {
            .col {
                .ck.ck-editor__editable_inline {
                    overflow: auto;
                    padding: 0 var(--ck-spacing-standard);
                    border-bottom: 1px solid var(--warn-color) !important;
                }
            }
        }
    }
}

.inactive-form {
    margin: 10px 0;
    border: 1px solid var(--primary-color);
    box-shadow: none;
}

.vertical-center {
    align-self: center;
    align-items: center;
}

.form-field-inactive {
    margin: 5px 0;
    display: flex;
    flex-direction: column;

    .form-inactive-label {
        color: #9d9fa1;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .mat-icon {
        font-size: 14px;
        height: 14px !important;
        width: 14px !important;
    }
}

.content-title {
    font-size: 18px;
    font-weight: 500;
}

.type-title {
    padding: 20px;
    font-size: 14px;
    color: var(--primary-color);
    white-space: nowrap;
}

.card-item {
    padding: 0 !important;
    margin: 10px !important;

    .mat-card-title--flex {
        min-height: 48px;
    }
}

.nfrp-subtitle {
    font-size: 18px;
    color: var(--primary-color);
}

.nfrp-subtitle-icon {
    font-size: 18px;
    color: var(--primary-color);
    margin: 0 !important;
}

.nfrp-item-form {
    padding: 0 30px 0 10px;
    .mat-form-field {
        border-radius: 4px;
        padding: 5px 10px;
        box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0,
            rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
        .mat-form-field-wrapper {
            margin: 0 !important;
            padding-bottom: 0 !important;
            .mat-form-field-flex {
                .mat-form-field-outline {
                    display: none;
                }
            }
        }
    }
}

.drag-and-drop-group {
    margin: 10px 0;
    padding: 10px;
}

.attribute {
    margin: 10px 0;
    align-items: center;
    padding-left: 10px;

    &.card {
        border-radius: 4px;
        padding: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding: 0.4375em 0;
    }

    .mat-raised-button {
        padding: 0 50px;
    }

    .mat-icon {
        cursor: grab;
    }
}

.attribute-item {
    background-color: #fff;
    &:only-child {
        .drag-item {
            display: none;
        }
    }
    mat-form-field {
        padding-top: 20px;
    }
    .ckeditor-label {
        margin-top: -9px;
    }
    .ck-content {
        padding-top: 0 !important;
    }
}

.button-margin {
    margin: 20px 0;
}

.inner-attribute {
    margin: 0 20px;
}

.mat-expansion-panel-header {
    h2 {
        margin: 0;
    }
}

.expansion-attributes-relationships {
    margin: 5px 0 !important;
    padding: 10px 0;

    .mat-expansion-panel-header-description {
        justify-content: space-between;
        align-items: center;
        margin-right: 30px;
    }
}

.disabled-card {
    opacity: 0.33;
}

.additional-form {
    border: 0.5px dashed rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 15px 20px;
    border-radius: 8px;
    margin-bottom: 10px;
    margin-top: 20px;
}
.additional-form:hover {
    .additional-form-title {
        color: var(--primary-color);
    }
}

.tab-column-order {
    .mat-tab-header {
        background-color: #ffffff;
        z-index: 1000;
        position: sticky;
        top: 0;
        border-radius: 4px;
        box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
        .mat-tab-label-content {
            font-size: 16px;
            font-weight: 550;
        }
    }
}

.svg-card {
    min-height: 30vh;
    overflow: hidden;
    svg {
        min-height: 30vh;
        max-height: 62vh;
        width: 100% !important;
    }
}

.svg-card-full-screen {
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 100;
    overflow: hidden;
    svg {
        min-height: 100%;
        max-height: 100%;
        width: 100% !important;
    }
}

.diagram-button-full-size {
    position: fixed;
    bottom: 3%;
    left: 45%;
    z-index: 100;
}

.action-card {
    padding: 0;
    margin: 0;
    border-radius: 5px;
    .mat-mdc-form-field {
        padding-top: 20px;
    }
    .cdk-drag-handle {
        cursor:ns-resize;
    }
}

.accordion-no-style {
    width: 100%;
    .mat-expansion-panel {
        box-shadow: none;
    }
}

.mat-accordion + .mat-accordion {
    .mat-expansion-panel {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
}

