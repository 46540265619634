.ck-sticky-panel {
    display: none;
}

.modal {
    .mention {
        cursor: initial;
    }
}

.mention {
    font-weight: 500;
    cursor: pointer;
    padding: 0 3px 2px;
    border-radius: 4px;

    &.validated {
        font-weight: bold;
    }

    &.revision {
        text-decoration: underline;
    }

    &.draft {
        font-style: italic;
    }

    &.deleted {
        text-decoration: line-through;
        pointer-events: none; //disable click on deleted mention
    }

    &[data-type='actor'] {
        color: var(--primary-color) !important;
    }

    &[data-type='system'] {
        color: var(--orange-color) !important;
    }

    &[data-type='requirement'] {
        color: var(--warn-color) !important;
    }

    &[data-type='glossaryterm'] {
        color: #737171 !important;
    }
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: #aeaeae !important;
}

use-case-scenario-form {
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
        border-color: transparent !important;
    }
}

.ck-content {
    border-radius: 4px !important;
    min-height: 50px;
    padding-left: 16px !important;
    padding-top: 5px !important;

    p {
        line-height: 1.2;
    }

    .mention {
        font-weight: 500;
        &[data-type='actor'] {
            background: rgba(176, 193, 239, 0.3) !important;
            color: var(--primary-color) !important;
        }
        &[data-type='system'] {
            background: rgba(243, 202, 139, 0.3) !important;
            color: var(--orange-color) !important;
        }
        &[data-type='requirement'] {
            color: var(--warn-color) !important;
        }
        &[data-type='glossaryterm'] {
            background: rgba(136, 150, 185, 0.3) !important;
            color: #737171 !important;
        }
    }
}

/**
 * Temp fix for select mentions ck-editor in modal in detail page with drawer
 */
.ck.ck-balloon-panel {
    z-index: 1500 !important;
}

/* Custom CKeditor with Material style */
app-material-ckeditor {
    display: grid;
    width: 100%;
}

.mat-mdc-form-field-infix.ck-editor-infix,
.ckeditor-form-field {
    width: 100%;
}

.mdc-text-field--outlined .mdc-floating-label {
    left: 2px !important;
}

.ckeditor-label {
    position: relative;
    margin-bottom: -9px;
    z-index: 1;
    margin-left: 12px;
    background-color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    color: rgba(93, 93, 93, 0.87);
}
