.sidenav-dependencies {
    position: fixed;
    width: 75%;
    overflow: hidden;
    z-index: 2000;
}

.mat-drawer-side {
    position: fixed !important;
    top: var(--toolbar-height) !important;
    width: 21%;
}

.sidenav-right-list {
    padding: 0 5px 0 5px;
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 10px;

    .navbar-comment {
        .comment-filter {
            padding: 10px 16px 16px;
            display: grid;
            grid-auto-rows: min-content;
            margin: 0 10px;
            box-shadow: none;

            .mat-mdc-form-field {
                .mat-mdc-form-field-subscript-wrapper {
                    display: none;
                }
            }
            .mat-mdc-form-field-focus-overlay {
                background-color: transparent;
            }
            .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                background-color: transparent;
            }

            .sidenav-title {
                font-size: 20px;
                font-weight: 500;
                text-align: left;
                align-self: center;
                margin-bottom: 8px;
            }

            .mat-form-field-infix {
                display: block;
                position: relative;
                flex: auto;
                min-width: 0;
                width: 100px;
            }
        }
    }

    .content {
        display: grid;
        grid-auto-rows: min-content;
        row-gap: 10px;

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        .mdc-card {
            margin: 0 10px 10px;

            .comment-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;

                .title {
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .comment-send {
                display: flex;
                justify-content: flex-end;
                margin-top: 5px;
                margin-bottom: 5px;

                .mat-raised-button {
                    margin: 0 10px;
                }
            }

            .new-comment {
                border-top: none;
            }
        }

        .mdc-card.inactive {
            background-color: #f1f1f1;

            p {
                color: grey;
            }

            .comment-content {
                color: grey;
            }

            mat-icon {
                visibility: hidden;
            }

            .reply-comment {
                padding: 5px;
            }
        }

        .mdc-card.new-card {
            display: none;
        }

        .mdc-card.new-card.visibility {
            display: block;
            box-shadow: none;
        }
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}

:not(.mat-drawer) {
    discussion-all-discussions discussion-list mat-nav-list.sidenav-right-list {
        padding-top: 0;
        padding-bottom: 0;
        .mdc-card.new-card.visibility {
            box-shadow: var(--mdc-elevated-card-container-elevation);
        }
        .comment-container {
            .mat-mdc-card {
                box-shadow: var(--mdc-elevated-card-container-elevation);
            }
        }
    }
}
