@use '@angular/material' as mat;

.container-login {
    display: flex;
    justify-content: center;
    padding-top: 100px;

    .login {
        width: 600px;
        padding: 40px;
        @include mat.elevation(3);

        @media (max-width: 767px) {
            width: 100%;
        }

        .mat-mdc-card-actions {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            gap: 15px;
        }

        .login-button {
            height: auto !important;
            padding: 12px 14px !important;

            .mdc-button__label {
                text-align: center !important;
            }
        }

        auth-google-button {
            margin-bottom: 20px;
        }
    }

    .mat-card-title {
        padding: 20px;
    }

    .spacing {
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
    }

    img {
        width: 350px;
        margin: 0 auto 30px auto;
    }

    input:-webkit-autofill {
        &,
        &:hover,
        &:focus,
        &:active {
            -webkit-box-shadow: 0 0 0 1000px white inset !important;
        }
    }
}

.light {
    display: initial;
}

.dark {
    display: none !important;
}
