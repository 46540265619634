.text-center {
    .mat-sort-header-container {
        justify-content: center;
    }
}

th.text-center {
    .mat-sort-header-container {
        margin-right: -16px;
    }
}

table.mat-table {
    border-radius: 5px;
    width: 100%;

    th,
    td {
        &:first-child {
            padding-left: 8px;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .td-name {
        font-weight: 500;
    }
}

.mat-column-action {
    white-space: nowrap;
}

td.mat-column-actionColumns .actions-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    .form-field-inactive {
        margin: 0;
    }
    .mat-mdc-button {
        background-color: #f5f5f5;
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .table-responsive {
        overflow-x: auto;
    }
}

.mat-mdc-cell {
    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: none;
    }

    .mat-expansion-panel-header {
        position: relative;
        padding: 0;
        height: 41px;
        font-weight: 400;

        &:hover {
            background: transparent !important;
        }
    }

    .mat-expansion-panel-body {
        padding: 0;
        .mat-mdc-list {
            .mat-mdc-list-item {
                height: auto;
            }
            .mat-mdc-list-item + .mat-mdc-list-item {
                margin-top: 10px;
            }
        }
    }
}

.tr-expandable {
    background-color: #f5f5f5;
}

.expansion-table {
    padding: 20px;

    table {
        border-radius: 10px;

        tr:nth-child(even) {
            background-color: #efefef;
        }
    }
}

.table-header {
    display: flex;
    justify-content: space-between;
    place-items: center;
}

.table-full {
    width: 100%;
}

.table-mat-list {
    &.mat-list-base {
        padding: 0;

        &.expanded-without-header {
            margin: -38px 10px 0 0;
        }
    }

    .mat-list-item-content {
        padding-left: 0 !important;
    }
}

.mat-expansion-panel-header {
    font-size: 14px;
    .mat-expansion-indicator {
        padding: 0 10px;
    }
}

.mat-mdc-table {
    th {
        font-size: 90%;
        font-weight: 600;
    }
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
    padding: 4px 8px !important;
    &.mat-column-code {
        width: 120px;
    }
    &.mat-column-dataTableColumns {
        width: 110px;
    }
    &.mat-column-perPage {
        width: 110px;
    }
    &.mat-column-nbFields {
        width: 110px;
    }
    &.mat-column-nbItems {
        width: 110px;
    }
    &.mat-column-nbUsagesOnUc {
        width: 110px;
    }
    &.mat-column-nbUsagesOnPage {
        width: 110px;
    }
    &.mat-column-nbNominalSteps {
        width: 110px;
    }
    &.mat-column-nbScenarios {
        width: 110px;
    }
    &.mat-column-nbActions {
        width: 110px;
    }
    &.mat-column-updatedAt {
        width: 150px;
    }
    &.mat-column-type {
        min-width: 80px;
    }

    &.mat-column-nbAttributes {
        width: 130px;
    }
    &.mat-column-nbRelations {
        width: 130px;
    }

    &.mat-column-actor {
        width: 200px;
    }
    &.mat-column-updatedBy {
        width: 200px;
    }
    &.mat-column-actions {
        max-width: 160px;
        width: 160px;
    }
    &.mat-column-action {
        max-width: 60px;
        width: 60px;
    }
    &.mat-column-nbOpenDiscussions {
        width: 110px;
    }
}
